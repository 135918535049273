<template>
    <div class="addresses">
        <!-- <div class="form-success" v-if="success">{{ success }}</div> -->
        <div class="address-list" v-if="addressListVisible">
            <h5 style="font-size: 36px; font-weight: bold; background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%) text; -webkit-text-fill-color: transparent; margin: 0px;">Addresses</h5>
            <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in $store.state.addresses"
                    :key="index"
                    @click="handleDeleteConfirmBoxClicks"
                    class="td-body address"
                  >
                    <td scope="row" style="border-top-left-radius: 20px; border-bottom-left-radius: 20px; vertical-align: middle;">{{ index + 1 }}</td>
                    <td style="vertical-align: middle;">{{ item.name }}</td>
                    <td style="vertical-align: middle;">
                      {{ item.contactName }} {{ item.contactMobile }}
                      {{ item.primary }} {{ item.secondary }},
                      {{ item.zipCode }} {{ item.cityName }},
                      {{ item.countryCode }}<br />
                      {{ item.landmark }}
                    </td>
                    <td style="width: 34%; border-top-right-radius: 20px; border-bottom-right-radius: 20px;">
                      <a
                        href="#"
                        class="btn edit"
                        style="background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); color: #fff; padding: 10px 40px; margin-right: 10px;     margin-bottom: 10px;"
                        @click.prevent="editAddress(index)"
                        >Edit</a
                      >
                      <a
                        href="#"
                        class="btn delete"
                        style="background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); color: #fff; padding: 10px 40px;     margin-bottom: 10px;"
                        @click.prevent="showDeleteConfirmBox($event, index)"
                        >Delete</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              
        </div>

        <div class="address-form" :class="addressForm">
            <a href="#" class="add-new" @click.prevent="newAddress()">
                <i></i>
                <span>Add new address</span>
            </a>

            <div class="form-outer-wrapper form-style-1" :class="formState">
                <div class="head">
                    <!-- <h5 data-default="New Address" ref="addressFormTitleEl">
                        {{ addressFormTitle }}
                    </h5> -->
                    <h5 data-default="New Address" ref="addressFormTitleEl" style="font-size: 36px; font-weight: bold; background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%) text; -webkit-text-fill-color: transparent; margin: 0px;">{{ isEditMode ? 'Edit Address' : 'Add New Address' }}</h5>
                    
                    <a
                        href="#"
                        class="close"
                        @click.prevent="closeAddressForm"
                    ></a>
                </div>
                <div class="form-wrapper">
                    <div class="form" style="padding: 0px 0px 50px 0px;">
                        <form
                            action=""
                            @focusout="change"
                            @input="change"
                            @submit="submit"
                            novalidate
                        >
                            <!-- <input type="hidden" v-model="address.id" /> -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid: validation.name.errorMessage,
                                            }"
                                        >
                                            <label for="name">Full Name</label>
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    data-rules='["required"]'
                                                    v-model="address.name"
                                                />
                                            </div>
                                        </div>
                                        <p class="error-message" v-if="validation.name.errorMessage">
                                            {{ validation.name.errorMessage }}
                                        </p>
                                    </div>
                                </div>
                        
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid: validation.contactEmail.errorMessage,
                                            }"
                                        >
                                            <label for="contactEmail">Email</label>
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    name="contactEmail"
                                                    id="contactEmail"
                                                    data-rules='["required"]'
                                                    v-model="address.contactEmail"
                                                />
                                            </div>
                                        </div>
                                        <p class="error-message" v-if="validation.contactEmail.errorMessage">
                                            {{ validation.contactEmail.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid:
                                                    validation.contactName.errorMessage,
                                            }"
                                        >
                                            <label for="contactName"
                                                >Contact Name</label
                                            >
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    name="contactName"
                                                    id="contactName"
                                                    data-rules='["required"]'
                                                    v-model="address.contactName"
                                                />
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.contactName.errorMessage"
                                        >
                                            {{ validation.contactName.errorMessage }}
                                        </p>
                                    </div>
                                </div> -->
                                
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="
                                                input-field-wrapper
                                                phone-input-field-wrapper
                                            "
                                            :class="{
                                                invalid:
                                                    validation.contactMobile
                                                        .errorMessage,
                                            }"
                                        >
                                            <label for="contactMobile">Mobile</label>
                                            <div class="input-field-inner-wrapper">
                                                <div class="dial-code">
                                                    {{ address.contactDialCode }}
                                                </div>
                                                <div class="input-field">
                                                    <input
                                                        type="text"
                                                        class="plain-input"
                                                        name="contactMobile"
                                                        id="contactMobile"
                                                        data-rules='["required"]'
                                                        v-model="address.contactMobile"
                                                    />
                                                </div>
                                            </div>
                                        </div>
        
                                        <p
                                            class="error-message"
                                            v-if="validation.contactMobile.errorMessage"
                                        >
                                            {{ validation.contactMobile.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid:
                                                    validation.country.errorMessage,
                                            }"
                                        >
                                            <label for="country">Country</label>
                                            <div class="input-field">
                                                <select
                                                    name="country"
                                                    placeholder="Search"
                                                    ref="countryDropdownEl"
                                                    data-rules='["required"]'
                                                    v-model="address.country"
                                                ></select>
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.country.errorMessage"
                                        >
                                            {{ validation.country.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div
                                        class="form-group"
                                    >
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid: validation.state.errorMessage,
                                            }"
                                            v-if="showStates"
                                        >
                                            <label for="state">State</label>
                                            <div class="input-field">
                                                <select
                                                    name="state"
                                                    placeholder="State"
                                                    ref="stateDropdownEl"
                                                    v-model="address.state"
                                                >
                                                    <option
                                                        :value="state.code"
                                                        v-for="state in $store.state
                                                            .uaeStates"
                                                        :key="state.code"
                                                    >
                                                        {{ state.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.state.errorMessage"
                                        >
                                            {{ validation.state.errorMessage }}
                                        </p>
                                    </div>
                                </div>
                            
                                <div class="form-sep"></div>

                                <div class="form-sep"></div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid:
                                                    validation.addressType.errorMessage,
                                            }"
                                        >
                                            <label for="email">Type of Address</label>
                                            <div class="input-field">
                                                <select
                                                    name="addressType"
                                                    placeholder="Address Type"
                                                    ref="addressTypeDropdownEl"
                                                    data-rules='["required"]'
                                                    v-model="address.addressType"
                                                >
                                                    <option value="Home">Home</option>
                                                    <option value="Office">
                                                        Office
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.addressType.errorMessage"
                                        >
                                            {{ validation.addressType.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid:
                                                    validation.landmark.errorMessage,
                                            }"
                                        >
                                            <label for="landmark" style="display: flex;"
                                                >Landmark
                                                <span style="margin: 2px 0px 4px 5px;">(Optional)</span>
                                            </label>
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    name="landmark"
                                                    id="landmark"
                                                    v-model="address.landmark"
                                                />
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.landmark.errorMessage"
                                        >
                                            {{ validation.landmark.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid:
                                                    validation.addressLine1
                                                        .errorMessage,
                                            }"
                                        >
                                            <label for="country">Address Line 1</label>
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    name="addressLine1"
                                                    id="addressLine1"
                                                    data-rules='["required"]'
                                                    v-model="address.addressLine1"
                                                />
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.addressLine1.errorMessage"
                                        >
                                            {{ validation.addressLine1.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid:
                                                    validation.zipCode.errorMessage,
                                            }"
                                        >
                                            <label for="country">Post Code</label>
                                            <div class="input-fields-row">
                                                <div class="input-field-col">
                                                    <div class="input-field">
                                                        <!-- data-rules='["required"]' -->
                                                        <input
                                                            type="text"
                                                            name="zipCode"
                                                            id="zipCode"
                                                            data-rules=""
                                                            v-model="address.zipCode"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.zipCode.errorMessage"
                                        >
                                            {{ validation.zipCode.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid:
                                                    validation.addressLine2
                                                        .errorMessage,
                                            }"
                                        >
                                            <label for="country" style="display: flex;"
                                                >Address Line 2
                                                <span style="margin: 2px 0px 4px 5px;">(Optional)</span>
                                            </label>
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    name="addressLine2"
                                                    id="addressLine2"
                                                    v-model="address.addressLine2"
                                                />
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.addressLine2.errorMessage"
                                        >
                                            {{ validation.addressLine2.errorMessage }}
                                        </p>
                                    </div>
                                </div>
    
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div
                                            class="input-field-wrapper"
                                            :class="{
                                                invalid: validation.city.errorMessage,
                                            }"
                                        >
                                            <label for="country">City</label>
                                            <div class="input-field">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    data-rules='["required"]'
                                                    v-model="address.city"
                                                />
                                            </div>
                                        </div>
                                        <p
                                            class="error-message"
                                            v-if="validation.city.errorMessage"
                                        >
                                            {{ validation.city.errorMessage }}
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div class="form-error" v-if="error">
                                <div class="error">{{ error }}</div>
                            </div>

                            <div class="form-action-group" style="text-align: end;">
                                <button type="submit" class="" style="background-image: linear-gradient(100deg, #1985A1 0%, #024455 100%); box-shadow: 0 2px 20px 0 rgb(148 102 0 / 0%); font-size: 16px; border-radius: 14px; min-width: 225px; padding: 0px 25px;">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="loading-overlay">
                    <div class="loader"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as TomSelect from "tom-select";
//import formValidation from '@/mixins/formValidation';
//import { mapGetters } from 'vuex';
import formValidation from "@/mixins/formValidation";

export default {
    name: "AddressList",
    mixins: [formValidation],
    data() {
        return {
            validation: {
                inputElements: [],
                name: {},
                contactName: {},
                contactMobile: {},
                contactEmail: {},
                country: {},
                state: {},
                addressType: {},
                addressLine1: {},
                addressLine2: {},
                landmark: {},
                zipCode: {},
                city: {},
            },
            addressListVisible: true,
            addressForm: null,
            addressFormTitle: "",
            editingAddressIndex: null,
            tsCountryDropdown: null,
            tsAddressTypeDropdown: null,
            address: {
                id: "",
                name: "",
                contactName: "test",
                contactMobile: "",
                contactEmail: "",
                contactDialCode: "+971",
                country: "AE",
                state: "",
                addressType: "",
                addressLine1: "",
                addressLine2: "",
                landmark: "",
                zipCode: "",
                city: "",
            },
            showStates:true,
        };
    },
    mounted() {
        this.initValidation();
        this.initAddressTypeDropdown();

        this.$store.dispatch("fetchCountries").then(() => {
            this.initCountryDropdown();
        });

        this.$store.dispatch("fetchUAEStates").then(() => {
            this.initStateDropdown();
        });

        this.addressFormTitle =
            this.$refs.addressFormTitleEl.getAttribute("data-default");

        this.$store.dispatch("fetchAddresses");
    },
    methods: {
        initCountryDropdown() {
            const countries = JSON.parse(
                JSON.stringify(this.$store.state.countries)
            );
            const flagsBaseUrl = this.$store.state.flagsBaseUrl;

            this.tsCountryDropdown = new TomSelect(
                this.$refs.countryDropdownEl,
                {
                    valueField: "code",
                    searchField: "name",
                    options: countries,
                    maxOptions: 300,
                    render: {
                        option: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                        item: function (data, escape) {
                            return (
                                '<div class="country">' +
                                '<div class="flag-wrapper">' +
                                '<div class="flag" style="background-image: url(\'' +
                                flagsBaseUrl +
                                data.flag +
                                "')\"></div>" +
                                "</div>" +
                                '<div class="name">' +
                                escape(data.name) +
                                "</div>" +
                                "</div>"
                            );
                        },
                    },
                    items: "AE",
                }
            );

            this.tsCountryDropdown.on("change", (country) => {
                let sCountry = countries.find((sCountry) => {
                    return sCountry.code == country;
                });

                if (sCountry.zipCodeRequired) {
                    document
                        .getElementById("zipCode")
                        .setAttribute("data-rules", '["required"]');
                } else {
                    document
                        .getElementById("zipCode")
                        .setAttribute("data-rules", "");
                }

                this.address.contactDialCode = sCountry.dialCode;

                console.log("i am changeing: ",country);
                this.showStates = country === "AE";

                if (this.showStates) {
                    this.$nextTick(() => {
                        this.initStateDropdown(); // Call your initialization method
                    });
                }
                
            });
        },
        initAddressTypeDropdown() {
            //{items: this.$refs.addressTypeDropdownEl.value}
            this.tsAddressTypeDropdown = new TomSelect(
                this.$refs.addressTypeDropdownEl
            );
        },
        initStateDropdown() {
            this.tsStateDropdown = new TomSelect(this.$refs.stateDropdownEl);
        },
        newAddress() {
            console.log('Adding a new address');
            this.isEditMode = false; // Set to false when adding a new address
            this.formState = 'show';
            this.addressForm = "new";
            // this.formState = null;
            this.addressListVisible = false;

            this.addressFormTitle =
                this.$refs.addressFormTitleEl.getAttribute("data-default");

            this.tsCountryDropdown.addItem("AE");
            this.tsAddressTypeDropdown.clear();
            this.tsCountryDropdown.addItem("DXB");

            this.address.id = new Date().getTime();
            this.address.name = "";
            this.address.contactName = "test";
            this.address.contactMobile = "";
            this.address.contactDialCode = "+971";
            this.address.contactEmail = "";
            this.address.country = "AE";
            this.address.state = "";
            this.address.zipCode = "";
            this.address.city = "";
            this.address.addressType = "";
            this.address.addressLine1 = "";
            this.address.addressLine2 = "";
            this.address.landmark = "";
        },
        editAddress(index) {
            console.log('Editing address at index:', index);
            this.addressForm = "edit";
            // this.formState = null;
            this.isEditMode = true;
            this.formState = 'show';

            this.resetBlur();

            let editingAddress = this.$store.state.addresses[index];

            this.editingAddressIndex = index;

            this.addressFormTitle = editingAddress["name"];

            this.tsCountryDropdown.addItem(editingAddress["countryCode"]);
            this.tsAddressTypeDropdown.addItem(editingAddress["type"]);
            if (editingAddress["state"] != null) {
                this.tsStateDropdown.addItem(editingAddress["state"]);
                this.address.state = editingAddress["state"];
            }

            this.address.id = editingAddress["id"];
            this.address.name = editingAddress["name"];
            this.address.contactName = editingAddress["contactName"];
            this.address.contactMobile = editingAddress["contactMobile"];
            this.address.contactEmail = editingAddress["contactEmail"];
            this.address.country = editingAddress["countryCode"];
            this.address.zipCode = editingAddress["zipCode"];
            this.address.city = editingAddress["cityName"];
            this.address.addressType = editingAddress["type"];
            this.address.addressLine1 = editingAddress["primary"];
            this.address.addressLine2 = editingAddress["secondary"];
            this.address.landmark = editingAddress["landmark"];
        },
        closeAddressForm() {
            this.addressForm = null;
            this.addressListVisible = true; // Show the address-list section again
        },
        addNewAddress(address) {
            //this.$store.state.addresses.push(address);

            this.$store
                .dispatch("addAddress", address)
                .then(() => {
                    this.formState = null;
                    this.addressForm = null;

                    window.dispatchEvent(
                        new CustomEvent("addSuccessMessage", {
                            detail: { message: "Address Added" },
                        })
                    );
                })
                .catch((error) => {
                    this.formState = null;
                    this.error = error;
                });
        },
        updateAddress(address) {
            //this.$store.state.addresses[this.editingAddressIndex] = address;

            this.$store
                .dispatch("updateAddress", address)
                .then(() => {
                    this.formState = null;
                    this.addressForm = null;

                    window.dispatchEvent(
                        new CustomEvent("addSuccessMessage", {
                            detail: { message: "Address Updated" },
                        })
                    );
                })
                .catch((error) => {
                    this.formState = null;
                    this.error = error;
                });
        },
        showDeleteConfirmBox(event, index) {
            let confirmBox = document.createElement("div");
            confirmBox.classList.add("confirm-box");
            confirmBox.innerHTML =
                `<div class='title'>Are you sure?</div>
                <div class='actions'>
                <a class='btn yes' data-index="` +
                index +
                `">Yes</a>
                <a class='btn no'>No</a>
                </div>`;
            event.target.closest(".address").appendChild(confirmBox);
        },
        deleteAddress(index) {
            this.$store
                .dispatch("deleteAddress", {
                    id: this.$store.state.addresses[index].id,
                })
                .then(() => {
                    window.dispatchEvent(
                        new CustomEvent("addSuccessMessage", {
                            detail: { message: "Address Deleted" },
                        })
                    );
                    //console.log('done');
                });
            /* .catch((error) => {
          //console.log('Eror', error);
        }); */
        },
        handleDeleteConfirmBoxClicks(e) {
            if (
                e.target &&
                e.target.classList.contains("btn") &&
                e.target.classList.contains("yes")
            ) {
                const index = e.target.getAttribute("data-index");
                this.deleteAddress(index);
                e.target.closest(".address").classList.add("hidden");
                e.target.closest(".confirm-box").remove();
            } else if (
                e.target &&
                e.target.classList.contains("btn") &&
                e.target.classList.contains("no")
            ) {
                e.target.closest(".confirm-box").remove();
            }
        },
        send() {
            this.error = null;

            // Use object.assign so its cloned and referenced is not copied after edit.
            let clonedAddress = Object.assign({}, this.address);
            let address = {
                name: clonedAddress.name,
                contactName: clonedAddress.name,
                contactMobile: clonedAddress.contactMobile,
                contactDialCode: clonedAddress.contactDialCode,
                contactEmail: clonedAddress.contactEmail,
                type: clonedAddress.addressType,
                primary: clonedAddress.addressLine1,
                secondary: clonedAddress.addressLine2,
                landmark: clonedAddress.landmark,
                cityName: clonedAddress.city,
                zipCode: clonedAddress.zipCode,
                countryCode: clonedAddress.country,
                state: clonedAddress.state,
            };

            if (this.addressForm == "new") {
                this.addNewAddress(address);
            } else {
                address.id = clonedAddress.id;
                this.updateAddress(address);
            }
        },
    },
};
</script>

<style lang="scss">
@import "../scss/partials/variables.scss";
@import "../../node_modules/tom-select/dist/css/tom-select.css";
@import "../scss/partials/tom-select-overrides.scss";

.form-style-1 {
    &.loading {
        .head {
            opacity: 0.1;
        }
    }
}
//.ts-control.single {
  //  width: 100% !important;
//}

.form-style-1.input-field-wrapper.ts-control.single.input-hidden {
    width: 100% !important;
}

.form-style-1.ts-input.items.ts-input.full.has-items {
    width: 100% !important;
}

.address-form .add-new {
    float: right;
    padding-right: 16px;
    border-radius: 5px;
    line-height: 66px !important;
    font-size: 14px !important;
    background-image: linear-gradient(100deg, #1985A1 0%, #024455 100%);
    color: #fff !important;
}
.address-form .add-new i {
    background-image: url('../assets/basil_add-outline.png') !important;
    width: 25px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 10px;
}

.address-list {
    .address {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.02);
        padding: 28px;
        display: flex;
        margin-bottom: 15px;
        position: relative;

        .name {
            font-size: 24px;
            font-weight: 600;
            color: #252423;
            flex: 1;
        }

        .full-address {
            font-size: 16px;
            line-height: 1.38;
            color: #252423;
            flex: 1;
        }

        .actions {
            flex: 1;
            text-align: right;

            .btn {
                padding: 8px 17px 8px 19px;
                border-radius: 18px;
                box-shadow: 0 2px 20px 0 rgba(148, 102, 0, 0.47);
                background-color: #e79f00;
                color: #ffffff;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                display: inline-block;
            }

            .delete {
                margin-left: 10px;
            }
        }

        .confirm-box {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .title {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            .actions {
                flex: 0;

                .btn {
                    margin: 0px 5px;
                }

                .yes {
                    background-color: #ff4e4e;
                }

                .no {
                    background-color: #21ab38;
                }
            }
        }

        &.hidden {
            display: none;
        }
    }
}

.input-field-wrapper {
    display: contents !important;
}

.address-form {
    border-radius: 5px;
    // background-color: rgba(188, 184, 254, 0.14);
    background-color: rgb(188 184 254 / 0%);

    .add-new {
        font-size: 20px;
        color: #9392ad;
        line-height: 95px;
        display: block;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            text-decoration: underline;
        }

        i {
            background-image: url("../assets/grey-new-address-icon.svg");
            width: 25px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
            margin: 10px;
        }
    }

    .form {
        max-width: 750px;
    }

    .form-outer-wrapper {
        display: none;
        position: relative;

        .head {
            padding: 30px 34px;
            border-bottom: 1px solid #ffffff;
            position: relative;

            h5 {
                color: #252423;
                font-size: 24px;
                font-weight: 500;
            }

            .close {
                width: 46px;
                height: 46px;
                background-color: #e9e2f2;
                border-radius: 50px;
                display: block;
                position: absolute;
                top: 50%;
                right: 30px;
                margin-top: -23px;
                background-image: url("../assets/black-cross-icon.svg");
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

    &.new,
    &.edit {
        .add-new {
            display: none;
        }

        .form-outer-wrapper {
            display: block;
        }
    }
}

@media (max-width: 890px) {
    .address-list {
        .address {
            flex-wrap: wrap;

            .name {
                flex: 0 0 100%;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 850px) {
    .address-form {
        .form-outer-wrapper {
            .head {
                padding: 20px;
                .close {
                    right: 20px;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .address-list {
        .address {
            padding: 20px;
        }
    }
}

@media (max-width: 640px) {
    .address-form {
        .form-outer-wrapper {
            .head {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    .address-list {
        .address {
            display: block;

            .full-address {
                margin-bottom: 20px;
            }

            .actions {
                text-align: left;
            }
        }
    }
}

.input-field {
    margin-top: 7px;
}

/* @media (max-width: 825px) {
  .address-list {
    .address {
      flex-wrap: wrap;

      > .actions {
        flex: 0 0 170px;

        .btn {
          margin-bottom: 10px;
        }
      }
    }
  }
} */
 
/*.items.ts-input.not-full {
    width: 200% !important;
} */

td {
    background-color: #F5F5F5;
    margin-bottom: 20px;
}

.table {
    border-collapse: separate;
    border-spacing: 0px 10px !important;
}

.address-list .address{
    padding: 0px;
    margin-bottom: 0px;
    display: table-row;
}
</style>
